// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';

import Inscripciones from './areas/public/Inscripciones';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Importar el logo
import logo from './assets/images/logo_magicexperience.jpg';

const PaginaGenerica = () => {
    const [eventCode, setEventCode] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setEventCode(e.target.value);
        setError(false);
    };

    const handleAccessClick = () => {
        if (eventCode.trim() === '') {
            setError(true);
        } else {
            navigate(`/inscripciones/${eventCode}`);
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {/* Utilizar el logo importado */}
            <img src={logo} alt="Logo" style={{ height: '100px' }} />
            <h1>Bienvenido a ME Eventos</h1>
            <TextField
                required
                label="C&oacute;digo del Evento"
                variant="outlined"
                margin="normal"
                value={eventCode}
                onChange={handleInputChange}
                error={error}
                helperText={error ? 'Debe proporcionar el c�digo del evento' : ''}
            />
            <br />
            <Button variant="contained" color="primary" onClick={handleAccessClick}>
                Acceder
            </Button>
        </div>
    );
};

const App = () => (
    <Router>
        <Routes>
            <Route path="/inscripciones/:eventSecretCode" element={<Inscripciones />} />
            <Route path="/inscripciones" element={<PaginaGenerica />} />
            <Route path="/" element={<PaginaGenerica />} />
        </Routes>
    </Router>
);

export default App;
