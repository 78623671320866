import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import customTheme from '@assets/theme'
import { ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './common/helpers/snackbarUtils';

ReactDOM.render(
    <ThemeProvider theme={customTheme}>
        <React.StrictMode>
            <SnackbarProvider>
                <SnackbarUtilsConfigurator />
                <App />
            </SnackbarProvider>

        </React.StrictMode>
    </ThemeProvider>,
    document.getElementById('root')
)
