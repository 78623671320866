import apiRequest from '@helpers/apiRequest';

//TODO RCY TERMINAR
const updateEventGuest = data => {
    const idEvent = data.idEvent;
    return apiRequest('PUT', `EventGuests/Update/${idEvent}`, data);
};
const newEventGuest = (data, eventSecretCode) => {
    return apiRequest('POST', `EventGuests/New?EventSecretCode=${eventSecretCode}`, data);
};
const deleteEventGuest = data => {
    const idEvent = data.idEvent;
    return apiRequest('DELETE', `EventGuests/Delete/${idEvent}`);
};

const getEventsGuests = idEventGuest => {
    return apiRequest('GET', `EventGuests/Details/${idEventGuest}`);
};



const serviceEventsGuests = {
    updateEventGuest,
    deleteEventGuest,
    newEventGuest,
    getEventsGuests
};

export default serviceEventsGuests;