import apiRequest from '@helpers/apiRequest';


const updateEvent = data => {
    const idEvent = data.idEvent;
    return apiRequest('PUT', `Events/Update/${idEvent}`, data);
};
const newEvent = data => {
    return apiRequest('POST', 'Events/New', data);
};
const deleteEvent = data => {
    const idEvent = data.idEvent;
    return apiRequest('DELETE', `Events/Delete/${idEvent}`);
};

const getEventsDetail = secretEventCode => {
    return apiRequest('GET', `Events/Details/${secretEventCode}`);
};



const serviceEvents = {
    updateEvent,
    deleteEvent,
    newEvent,
    getEventsDetail
};

export default serviceEvents;