import React, { useState, useEffect } from 'react';
import serviceEvents from '@services/serviceEvents';
import serviceEventsGuests from '@services/serviceEventsGuests';
import { useParams, useNavigate } from 'react-router-dom';

import {
    TextField,
    TextareaAutosize,
    Grid,
    Card,
    Button,
    Box,
    Avatar,
    Link,
    Typography,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { snackActions } from '@helpers/snackbarUtils'
import logo from '@assets/images/logo_magicexperience_black.jpg';
import ReCAPTCHA from "react-google-recaptcha";

const Inscripciones = () => {
    const { eventSecretCode } = useParams();
    const [eventDetails, setEventDetails] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        guestName: '',
        guestPersonalRemarks: '',
        guestEventRemarks: '',
        guestPhone: '',
        guestEmail: '',
    });

    const navigate = useNavigate();

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };

    const RECAPTCHA_SITE_KEY = "6LdnikEpAAAAAKT5_0ZticDm39lAyYv0Rl5y8g8D";
    const handleRecaptchaChange = (value) => {
        console.log("Cambio de reCAPTCHA:", value);
        // Puedes realizar acciones adicionales si es necesario
    };

    const fetchEventDetails = async () => {
        try {
            if (eventSecretCode) {
                const response = await serviceEvents.getEventsDetail(eventSecretCode);

                if (response.data) {
                    setEventDetails(response.data);
                } else {
                    console.error('Detalles del evento no encontrados en la respuesta del servicio:', response);
                    setEventDetails(null);
                }
            } else {
                console.error('eventSecretCode es undefined. No se realizar� la llamada al servicio.');
                navigate('/error');
            }
        } catch (error) {
            console.error('Error al obtener los detalles del evento:', error);
        }
    };

    const formatFecha = (fechaISO) => {
        const fecha = new Date(fechaISO);
        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };

        return fecha.toLocaleDateString('es-ES', options);
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { guestName, guestPersonalRemarks, guestEventRemarks, guestPhone, guestEmail } = formData;
            const newGuest = {
                IdEvent: eventDetails.idEvent,
                GuestName: guestName,
                GuestPersonalRemarks: guestPersonalRemarks,
                GuestEventRemarks: guestEventRemarks,
                GuestPhone: guestPhone,
                GuestEmail: guestEmail,
            };

            const response = await serviceEventsGuests.newEventGuest(newGuest, eventSecretCode);
            debugger;
            if (!response.error) {
                // Manejar la respuesta seg�n tus necesidades
                console.log('Invitado registrado con �xito:', response.data);
                snackActions.success('Invitado registrado con �xito:')
            } else {
                console.error('Error al registrar el invitado:', response);
                snackActions.error('Error al registrar el invitado');
            }
        } catch (error) {
            console.error('Error al registrar el invitado:', error);
            snackActions.error('Error al registrar el invitado');
        }
    };

    useEffect(() => {
        fetchEventDetails();
    }, [eventSecretCode, navigate]);

    return (
        <div>
            {/* Cabecera */}
            <div
                style={{
                    backgroundColor: '#E2B31E',
                    color: 'white',
                    textAlign: 'center',
                    padding: '10px',
                    fontFamily: 'Kurale',
                }}
            >
                <Typography variant="h3">
                    ME Eventos
                </Typography>
                (by <a href="https://www.magicsolutions.es" target="_blank" rel="noopener noreferrer">Magic Solutions</a>)
            </div>

            {/* Info Evento */}
            <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
                Inscripciones
            </Typography>
            <div id="infoEvento" style={{ maxWidth: '600px', margin: 'auto', fontFamily: 'Kurale' }}>
                {eventDetails && typeof eventDetails === 'object' ? (
                    <>
                        <Typography variant="h5" gutterBottom style={{ textAlign: 'center', color: '#0085B4' }}>
                            {eventDetails!==null ? eventDetails.eventName : ''}
                            <Typography variant="body2" color="black">
                                &nbsp;(<Link href="#" onClick={openModal}>M&aacute;s info.</Link>)
                            </Typography>
                        </Typography>
                        <p>Fecha: {eventDetails.eventDateFrom && formatFecha(eventDetails.eventDateFrom)}</p>
                    </>
                ) : eventDetails === null ? (
                    <p>No se ha encontrado el evento</p>
                ) : (
                    <p>Cargando detalles del evento...</p>
                )}
            </div>

            <hr/>
            {/* Formulario */}
            {eventDetails && typeof eventDetails === 'object' ? (
                <div id="formulario" style={{ maxWidth: '600px', margin: 'auto', marginBottom:'30px' }}>
                    <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: 'auto' }}>
                        <h3>Registro de Invitado</h3>
                        <TextField
                            required
                            label="Nombre y apellidos"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="guestName"
                            value={formData.guestName}
                            onChange={handleInputChange}
                        />

                        <TextField
                            required
                            label="Tel&eacute;fono"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="guestPhone"
                            value={formData.guestPhone}
                            onChange={handleInputChange}
                        />

                        <TextField
                            label="Correo electr&oacute;nico"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="guestEmail"
                            value={formData.guestEmail}
                            onChange={handleInputChange}
                        />
                        <Box paddingTop={2}>Informaci&oacute;n a tener en cuenta (alergias, preferencias, ...)</Box>
                        <TextareaAutosize
                            minRows={4}
                            style={{ width: '100%', border: '1px solid lightgray', borderRadius: '5px', margin: '5px 0px', fontSize: '14px', padding: '10px' }}
                            placeholder="Informaci&oacute;n a tener en cuenta (alergias, preferencias, ...)"
                            id="guestPersonalRemarks"
                            name="guestPersonalRemarks"
                            value={formData.guestPersonalRemarks}
                            onChange={handleInputChange}
                        />

                        <Box paddingTop={2}>Comentarios sobre el evento</Box>
                        <TextareaAutosize
                            minRows={4}
                            style={{ width: '100%', border: '1px solid lightgray', borderRadius: '5px', margin: '5px 0px', fontSize: '14px', padding: '10px' }}
                            placeholder="Comentarios sobre el evento"
                            id="guestEventRemarks"
                            name="guestEventRemarks"
                            value={formData.guestEventRemarks}
                            onChange={handleInputChange}
                        />

                        {/* Checkbox */}
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Checkbox
                                checked={isCheckboxChecked}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                            <Typography variant="body2" color="textSecondary">
                                Acepto que mi informaci&oacute;n se guarde en los archivo de Magic Experience S.L. para la gesti&oacute;n del evento y acepto su <a href="https://magicexperience.es/politica-privacidad/">Pol&iacute;tica de privacidad</a>
                            </Typography>
                        </Box>

                        {/* reCAPTCHA */}
                        <Box paddingTop={2} textAlign="center">
                            <ReCAPTCHA
                                sitekey={RECAPTCHA_SITE_KEY}
                                onChange={handleRecaptchaChange}
                                size="invisible"
                            />
                        </Box>

                        {/* Bot�n de registro */}
                        <Box paddingTop={2} textAlign="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={!isCheckboxChecked} // Deshabilitar el bot�n si el checkbox no est� marcado
                            >
                                Registrarse
                            </Button>
                        </Box>
                    </form>
                </div>
            ) : (
                <></>
            )}

            {/* Pie */}
            <div
                style={{
                    backgroundColor: '#E2B31E',
                    color: 'black',
                    textAlign: 'center',
                    padding: '10px',
                    fontFamily: 'Kurale',
                }}
            >
                &copy; <a href="https://www.magicexperience.es" target="_blank" rel="noopener noreferrer">Magic Experience S.L.</a> -
                Desarrollado por (<a href="https://www.magicsolutions.es" target="_blank" rel="noopener noreferrer">www.magicsolutions.es</a>)
            </div>

            {/* Dialog de Material-UI */}
            <Dialog open={modalOpen} onClose={closeModal} maxWidth="md">
                <DialogTitle>{eventDetails!==null ? eventDetails.eventName : ''}</DialogTitle>
                <DialogContent>
                    <DialogContentText dangerouslySetInnerHTML={{ __html: eventDetails!==null ? eventDetails.eventInfo : '' }} />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={closeModal} style={{ color: 'black', backgroundColor: '#E2B31E', fontWeight:'bold' }}>
                        Cerrar
                    </Button>
                </DialogActions>

                <IconButton
                    style={{ position: 'absolute', top: '5px', right: '5px', color: 'gray' }}
                    onClick={closeModal}
                >
                    <CloseIcon />
                </IconButton>
            </Dialog>
        </div>
    );
};

export default Inscripciones;
